:root {
    --ebony-50: #f3f4fa;
    --ebony-100: #d7d7f0;
    --ebony-200: #afaee1;
    --ebony-300: #827eca;
    --ebony-400: #5b53ae;
    --ebony-500: #423993;
    --ebony-600: #352c75;
    --ebony-700: #2d265f;
    --ebony-800: #28224d;
    --ebony-900: #08070e;
    // 
    --primary: var(--ebony-500);
    --primaryVariant: var(--ebony-700);
    --secondary: var(--ebony-500);
    --onBg: #ffff;
    --background: var(--ebony-900);
    --error: #cf6679;
    --surface: var(--ebony-800);
    --empty: #807b8e;
}

// 
$primary: var(--primary);
$primaryVariant: var(--primaryVariant);
$secondary: var(--secondary);
$onBg: var(--onBg);
$background: var(--background);
$error: var(--error);
$surface: var(--surface);
$empty: var(--empty);
// Utils
$primaryColor: $onBg;