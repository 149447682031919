#root .react-select{
    &__control{
        border-radius: 0;
        background: none;
        border-color: $empty;
        &--is-focused{
            border:1px solid $primary!important;
            box-shadow: 0 0 0px $primary!important;
        }
    }
    &__indicator-separator{
        display: none;
    }
    &__value-container{
        padding: 0 10px;
    }
    &__input-container{
        margin: 0;
        padding: 0;
        color: white;
    }
    &__option{
        color: black;
    }
    &__input{
        &:focus {
            outline: none !important;
            border:0px solid $primary!important;
            box-shadow: 0 0 0px $primary!important;
          }
    }
}
