@import './_Variables.scss';

.HomePage {
    h1 {
        font-size: 32px;
    }

    h2 {
        color: $primary;
    }
}