.form{
    width: 80%;
    margin: auto;
    padding: 2rem 3rem;
    background: var(--surface);
    border-radius: 15px;
    >div + div{
        margin-top: 1rem;
    }

    >input{
        margin-top: 1rem;
    }
}

.showPassword{
    label{
        margin-right: 15px;
    }
}