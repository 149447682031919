.commits {
    &_item {
        margin: 2px 0;

        &__title {
            font-size: 15px;

            @media screen and (max-width: 768px) {
                display: block;
                margin-bottom: 10px;
            }
        }

        &__id {
            font-size: 10px;
            display: inline-block;
            margin-right: 10px;
            background: var(--primaryVariant);
            padding: 2px 4px;
            min-width: 63px;
            text-align: center;
            border-radius: 5px;
            border: 1px dashed var(--primary);
        }

        &__date {
            color: var(--primary);
            font-size: 12px;
            margin-right: 10px;
            min-width: 58px;
            text-align: center;
            display: inline-block;
        }
    }
}