@tailwind base;
@tailwind components;
@tailwind utilities;
@import './_Variables.scss';
@import './Publics.scss';


body{
    background-color: $background;
    color: $primaryColor;
}


