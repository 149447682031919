#root{
    [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date']{
        height: 46px;
        width: 100%;
    }
    [type='text'], [type='email'], [type='url'], [type='password'], [type='number'], [type='date'], [type='datetime-local'], [type='month'], [type='search'], [type='tel'], [type='time'], [type='week'], [multiple], textarea, select {

        background: transparent;
        border: 1px solid $empty;
        color: $onBg;
        &:focus {
            outline: none !important;
            border:1px solid $primary!important;
            box-shadow: 0 0 5px $primary!important;
        }
        &::placeholder{
            color: $empty;
        }
    }

    select option{
        color: $background;
    }
}
@import './ReactSelect.scss';

.button_submit{
    color: white;
    border: 1px solid white;
    cursor: pointer;
    height: 46px;
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover{
        background-color: $primary!important;  
        color: $onBg!important;
    }
}