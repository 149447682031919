.menuContainer{
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 10px;
    span{
        display: flex;
        align-items: center;
        &:hover{
            color: var(--ebony-100);
        }
    }
}