@import './_Variables.scss';
.Tabela {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    thead tr {
        background-color: $primary;
        color: #ffffff;
        text-align: left;
    }

    th,
    td {
        padding: 12px 15px;
    }

    tbody {
        tr {
            border-bottom: 1px solid $primary;
            color: white;
            &:last-of-type {
                border-bottom: 2px solid $primary;
            }
        }
    }

}