.JetAccordion{
    &_button{
        width: 100%;
        position: relative;
        border: 1px solid var(--primary);
        padding: 10px 15px;
        color: var(--primary);
        cursor: pointer;
        display: flex;
        justify-content: space-between;
    }
    &_container{
        justify-content: space-between;
    }
    a{
        display: block;
        min-width: 125px;
        padding: 5px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
        border: 1px solid var(--primary);
        text-align: center;
        color: var(--primary);
        &:hover{
            background-color: var(--primary);
            color: var(--onBg);
        }
    }
}
